var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-sheet', {
    attrs: {
      "height": "auto"
    }
  }, [_c('calendar', {
    attrs: {
      "month": _vm.month,
      "type": "month"
    },
    on: {
      "update:month": function updateMonth($event) {
        _vm.month = $event;
      }
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }