<template>
  <v-container fluid>
    <v-sheet height="auto">
      <calendar :month.sync="month" type="month" />
    </v-sheet>
  </v-container>
</template>

<script>
import dayjs from '@/plugins/dayjs'
import Calendar from '@/components/admin/partials/Calendar/Calendar'
export default {
  name: 'Monthly',
  components: { Calendar },
  computed: {
    currentMonth() {
      return dayjs()
    }
  },

  data: () => ({
    month: dayjs()
  }),
  methods: {}
}
</script>

<style src="./Monthly.scss" lang="scss" scoped></style>
